const ID_THEME_KEY = 'lycaste_theme'

export const getTheme = () => {
  return window.localStorage.getItem(ID_THEME_KEY)
}

export const saveTheme = theme => {
  window.localStorage.setItem(ID_THEME_KEY, theme)
}

export const clearTheme = () => {
  window.localStorage.removeItem(ID_THEME_KEY)
}

export default { getTheme, saveTheme, clearTheme }
