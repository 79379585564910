/**
 * @module useConfigStore
 * @description This store is used to save and modify app configration. This is global store which can be used anywhere. It contains settings for grid, layout, theme, timezone, device type and so on.
 */

import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import { darkTheme } from 'naive-ui'
import ThemeService from '@/services/theme.service'

export const useConfigStore = defineStore('config', () => {
  /* ========================= */
  /* ===== Grid & Layout ===== */
  /* ========================= */

  /**
   * @constant windowSizes [mutable]
   * @type {Object}
   * @description Variable containing reatlime window width and height
   * @param {Number} width - window width in px
   * @param {Number} height - window width in px
   */
  const windowSizes = ref({
    width: undefined,
    height: undefined
  })

  /**
   * @constant gridBreakpoints [declarable]
   * @type {Object}
   * @description
   * Variable with breakpoints used in project.
   * Keep in mind that those breakpoints must be equal with the same in SCSS and Naive UI
   * Open file styles/@core/_variables.scss to see $breakpoints map
   * @see {@link https://www.naiveui.com/en-US/dark/docs/customize-theme}
   * @see {@link https://www.naiveui.com/en-US/dark/components/config-provider}
   */
  const gridBreakpoints = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400
  }

  /**
   * @constant currentBreakpoint [computable]
   * @type {String}
   * @description Reactively updates with window resize event
   * @returns
   * String corresponding to the current window width.
   * 'xs' | 'sm' | 'md' | 'lg' | 'xl'
   * @default 'xs'
   * @example
   * <div v-if="configState.currentBreakpoint == 'md'">Visible on MD</div>
   */
  const currentBreakpoint = computed(() => {
    let result = 'xs' // return 'xs' be default
    Object.entries(gridBreakpoints).forEach(entry => {
      if (windowSizes.value.width >= [entry[1]]) {
        result = entry[0]
      }
    })
    return result
  })

  /**
   * @constant currentLayout [computable]
   * @type {String}
   * @returns Current router page layout type stored in {meta}
   * @see {@link https://router.vuejs.org/guide/advanced/meta.html}
   */
  const currentLayout = computed(() => {
    const route = useRoute()
    return route.meta.layout
  })

  /**
   * @constant showHeader [computable]
   * @type {Boolean}
   * @description Function returns true if current route.meta.layout includes defined types of layout. By default <header> is visible only in 'chat' and 'dashboard' layput types
   */
  const showHeader = computed(() => {
    const route = useRoute()
    return ['chat', 'dashboard'].some(el => el == route.meta.layout)
  })

  /**
   * @function screenLargerThen
   * @description Helps to detect if window reached specific breakpoint
   * @param {String} breakpoint - value based on gridBreakpoints
   * @returns {Boolean}
   * @example
   * <div v-if="configStore.screenLargerThen('lg')">Visible on LG+</div>
   */
  function screenLargerThen(breakpoint) {
    return windowSizes.value.width >= gridBreakpoints[breakpoint]
  }

  /**
   * @function saveWindowSizes
   * @description Reactively saves current window width and height
   */
  function saveWindowSizes() {
    windowSizes.value.width = window.innerWidth
    windowSizes.value.height = window.innerHeight
  }

  /**
   * @function watchWindowSizes
   * @event resize
   * @description Continously watches for window resize event and saves window sizes
   */
  function watchWindowSizes() {
    saveWindowSizes()
    window.addEventListener('resize', saveWindowSizes)
  }


  /* ====================== */
  /* ===== Menu State ===== */
  /* ====================== */

  /**
   * @constant menuOpened [mutable]
   * @type {Boolean}
   * @default false
   * @description Shows current menu state implemented with NaiveUI drawer component
   */
  const menuOpened = ref(false)

  /**
   * @function switchMenuState
   * @description Switches current menu state
   */
  function switchMenuState() {
    menuOpened.value = !menuOpened.value
  }


  /* ================================== */
  /* ===== Window scroll position ===== */
  /* ================================== */

  /**
   * @constant windowScroll [mutable]
   * @type {Number} Current scroll position in px (window.scrollY)
   * @default 0
   * @description Updates real-time on window scroll event
   */
  const windowScroll = ref(0)

  /**
   * @function saveWindowScroll
   * @description Reactively saves current scroll position
   */
  function saveWindowScroll() {
    windowScroll.value = window.scrollY
  }

  /**
   * @function watchWindowScroll
   * @event scroll
   * @description Continously watches for window scroll event
   */
  function watchWindowScroll() {
    saveWindowScroll()
    window.addEventListener('scroll', saveWindowScroll)
  }


  /* =================================== */
  /* ===== Client Browser Timezone ===== */
  /* =================================== */

  /**
   * @constant timezone [mutable]
   * @type {String}
   * @description Detected client timezone in TZ Database format. Used for <b-time> NaiveUI component
   * @see {@link https://en.wikipedia.org/wiki/List_of_tz_database_time_zones}
   * @see {@link https://www.naiveui.com/en-US/os-theme/components/time}
   * @example 'Africa/Bamako'
   * @example 'Europe/Chisinau'
   * @example 'Poland'
   */
  const timezone = ref('')

  /**
   * @function setTimezone
   * @description Saves client timezone in reactive variable
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions}
   * @see {@link https://caniuse.com/?search=Intl.DateTimeFormat().resolvedOptions().timeZone}
   * @see {@link https://philna.sh/blog/2021/02/22/display-dates-in-your-users-time-zone/}
   */
  function setTimezone() {
    timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone
  }


  /* ========================= */
  /* ===== Website Theme ===== */
  /* ========================= */

  /**
   * @constant currentTheme [mutable]
   * @type {Object|null}
   * @default null
   * @description Currently used NaiveUI theme
   * @see {@link https://www.naiveui.com/en-US/os-theme/docs/customize-theme}
   */
  const currentTheme = ref(null)

  /**
   * @function toggleTheme
   * @description Toggles NaiveUI theme (switches between light & dark)
   */
  function toggleTheme() {
    currentTheme.value = currentTheme.value ? null : darkTheme
    ThemeService.saveTheme(currentTheme.value ? 'dark' : 'light')
  }

  /**
   * @function restoreTheme
   * @description Restores NaiveUI theme from browser localStorage on page load
   */
  function restoreTheme() {
    const storedTheme = ThemeService.getTheme()
    currentTheme.value = storedTheme == 'light' || !storedTheme ? null : darkTheme
  }


  /* =============================== */
  /* ===== Project Environment ===== */
  /* =============================== */

  /**
   * @constant environment [mutable]
   * @type {Object}
   * @default {}
   * @description Current VITE environment props
   */
  const environment = ref({})

  /**
   * @function saveEnvironment
   * @description Saves current VITE enironment props
   * @see {@link https://vitejs.dev/guide/env-and-mode.htm
   */
  function saveEnvironment() {
    environment.value = import.meta.env
  }


  /* ======================= */
  /* ===== Device Type ===== */
  /* ======================= */

  /**
   * @constant deviceType [mutable]
   * @type {String}
   * @default 'mobile'
   * @description Detected device type
   */
  const deviceType = ref('mobile')

  /**
   * @function saveDeviceType
   * @description Saves detected device type to reactive variable
   * @see {@link https://stackoverflow.com/a/3540295/6814267}
   */
  function saveDeviceType() {
    deviceType.value = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop'
  }

  /**
   * @function watchDeviceType
   * @event resize
   * @description Continously watches for window resize event and saves device type
   */
  function watchDeviceType() {
    saveDeviceType()
    window.addEventListener('resize', () => { saveDeviceType() })
  }


  return {
    windowSizes,
    gridBreakpoints,
    currentBreakpoint,
    currentLayout,
    showHeader,
    screenLargerThen,
    saveWindowSizes,
    watchWindowSizes,

    menuOpened,
    switchMenuState,

    windowScroll,
    saveWindowScroll,
    watchWindowScroll,

    timezone,
    setTimezone,

    currentTheme,
    toggleTheme,
    restoreTheme,

    environment,
    saveEnvironment,

    deviceType,
    saveDeviceType,
    watchDeviceType,
  }
})
